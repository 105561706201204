.pointer {
    cursor: pointer;
}

.no-pointer {
    cursor: default !important;
}

ion-router-outlet {
    top: var(--roulet-height);
}

:root {
    --roulet-height: 44px !important;
}

ion-icon {
    pointer-events: none;
}

.searchbar-input.sc-ion-searchbar-md {
    padding-inline-start: 40px !important;
    padding-inline-end: 10px !important;
}

.font-weight-bold {
    font-weight: bold;
}

//swiper
.swiper-pagination-bullet-active {
    background-color: var(--ion-color-primary);
}

.swiper-button-next, .swiper-button-prev {
    color: var(--ion-color-primary) !important;
}

.swiper .swiper-slide{
    height: unset!important;
}

ion-footer {
    background: white;
}

.primary-color {
    color: var(--ion-color-primary) !important;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline{
    text-decoration: underline;
}

.curafida-underline {
    display:inline-block;
    border-bottom:1px solid black;
    padding-bottom:2px;
}

.word-breaking {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    white-space: break-spaces;
}

.media-background {
    background: #eeeeee ;
    --background: #eeeeee ;
}

.title-header {
    font-weight: bold;
    font-size: 120% !important;
}
.title-primary {
    font-weight: bold;
    font-size: 120% !important;
    color: var(--ion-color-primary);
}

.background-light {
    background: var(--ion-color-light);
}
.background-primary {
    background: var(--ion-color-primary);
    --background: var(--ion-color-primary);
}
.background-primary-contrast {
    background: var(--ion-color-primary-contrast);
    --background: var(--ion-color-primary-contrast);
}
.color-primary {
    color: var(--ion-color-primary);
    --color: var(--ion-color-primary);
}
.color-primary-contrast {
    color: var(--ion-color-primary-contrast);
    --color: var(--ion-color-primary-contrast);
}

ion-content::part(scroll) {
    overflow-y: scroll;
}

.fc-col-header-cell-cushion {
    font-weight: normal!important;
}

.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
