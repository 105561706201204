.header {
    border-bottom: 2px solid var(--ion-color-light-shade);
    vertical-align: bottom;
    font-size: 88%;
    font-weight: 700;
    padding-top: 1rem;
    width: 100%;
}

.lines {
    border-bottom: 1px solid var(--ion-color-light-shade);
    background: white !important;
    width: 100%;
    padding-top: 0.25rem;
    align-items: center;
}

.label {
    font-size: 80%;
}

ion-checkbox {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    min-width: 1.25rem;
}

.filter-column {
    min-width: 25%;
    max-width: 25%;
    padding-right: 10px;
    flex-grow: 1
}

@media (max-width: 1024px) {
    .header {
        border-bottom: 2px solid var(--ion-color-light-shade);
        vertical-align: bottom;
        font-size: 88%;
        font-weight: 700;
        padding-top: 1rem;
        width: 100%;
    }

    .lines {
        border-bottom: 1px solid var(--ion-color-light-shade);
        background: white !important;
        width: 100%;
        padding-top: 0.25rem;
    }
}

.mobile-filter-column {
    width: 100%;
    flex-grow: 1
}

