/**
Global image
*/
.small-icon {
  width: 20px !important;
  height: 20px;
  margin: 7px;
}

.medium-icon {
  width: 40px;
  height: 40px;
  margin: 10px;
}

.small-medium-icon-right-margin {
    width: 30px !important;
    height: 30px;
    margin-right: 10px;
}

// TODO inside MediaContentModelComponent?
.responsive-large-icon {
    width: 30vh;
    height: 30vh;
    max-height: 230px;
    max-width: 230px;
}

// Responsive
.responsive-large-image {
    width: 40vh;
    height: 40vh;
    max-height: 600px;
    max-width: 600px;
}
// TODO: @Paul is this class used for the ButtonItemAdapterComponent? I think it is not clear/intuitive
#mia-button  {
    height: 100% !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

//tablet device
@media (min-width: 769px) and (min-height: 571px) {
  .small-icon {
    width: 20px !important;
    height: 20px;
    margin: 7px;
  }

  .medium-icon {
    width: 40px !important;
    height: 40px;
    margin: 10px;
  }
}

// Mobile device
@media (max-width: 768px), (max-height: 570px) {
  .small-icon {
    width: 10px !important;
    height: 10px;
    margin: 5px;
  }

  .medium-icon {
    width: 30px !important;
    height: 30px;
    margin: 10px;
  }
}


.swiper .swiper-slide img {
    width: 100%!important;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}
