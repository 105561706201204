/**
Global web
*/

@media (min-width: 901px) {
    .wrapper {
        position: relative;
        max-width: var(--curafida-wrapper-width);
        margin: 0 auto 30px;
        min-height: 375px;
    }

    .wrapper-without-height {
        max-width: var(--curafida-wrapper-width);
        margin-left: auto;
        margin-right: auto;
        padding: 0 !important;
        --padding: 0 !important;
    }

    .form-button {
        padding-left: 0;
        width: 50%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        background: white;
        margin-top: 1rem;
    }

    ion-header {
        background: var(--ion-color-primary) !important;
    }

    .mobile {
        display: none !important;
    }

    .my-custom-modal-css .modal-wrapper {
        //top: 10%;
        position: absolute;
        display: block;
        width: var(--curafida-wrapper-width);
        min-height: 20%;
    }


    .modal-square-css .modal-wrapper {
        position: absolute;
        display: block;
        width: 800px;
    }

    .wrapper-without-height-login {
        margin-left: auto;
        margin-right: auto;
        padding: 0 !important;
        --padding: 0 !important;
        max-width: var(--curafida-login-wrapper-width);
    }

    lib-curafida-page-title {
        padding-bottom: 1vh;
        margin-bottom: 1vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: flex-start;
        border-bottom: 1px solid var(--ion-color-primary);
    }

    .sc-ion-popover-md {
        --width: 23rem;
        padding: 1px;
        left: -12px;
    }
    .sc-ion-popover-ios {
        --width: 23rem;
        padding: 1px;
        left: -12px;
    }
}
