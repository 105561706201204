.table thead th {
    border-bottom: 0 solid var(--ion-color-light-shade);
    vertical-align: bottom;
    font-weight: bold;
    padding-top: 1rem;
    padding-bottom: 0.75rem;
    position: sticky;
    top: 0;
}

table {
    position: relative;
    border-collapse: collapse;
}

.font-table-header {
    font-size: 16px!important;
}

th {
    background: white;
    position: sticky;
    white-space: pre-wrap;
    top: 0; /* Don't forget this, required for the stickiness */
}

.table thead tr {
    border-bottom: 2px solid var(--ion-color-light-shade);
    text-align: left;
}

.table td, .table th {
    font-weight: normal;
    padding: .5rem 0;
    display: flex;
    align-items: center;
    border-top: none
}

td {
    //line-height: 100%;
    // TODO: To finde another solution, height should expand until reach tr height.
    min-height: 50px;
}

tr {
    border-bottom: 1px solid var(--ion-color-light-shade);
    background: white!important;
}

.past-date-warning{
    color: #ff0000;
    font-weight: bold;
}

.web-bold {
    font-weight: bold;
}
