.sub-page {
    text-transform: uppercase;
    color: var(--ion-color-primary) !important;
    font-weight: bold !important;
    height: 2rem !important;
    --color-hover: var(--ion-color-primary-contrast) !important;
    --background-hover: var(--ion-color-primary) !important;
}

.sub-page-active {
    text-transform: uppercase;
    font-weight: bold !important;
    height: 2rem !important;
    color: var(--ion-color-primary) !important;
    background: var(--ion-color-primary-contrast) !important;;
    --color-hover: var(--ion-color-primary) !important;
}

.sub-page-display-none {
    display: none !important;
}

.popover-custom-class {
    --width: 85%;
    --margin: 8px;
    --height: auto!important;
}
.popover-modal-class {
    --width: 22rem;
    --margin: 8px;
    --height: auto!important;
}

