/**
Global tablet
*/
@media (min-width: 751px) and (max-width: 900px) {
  .wrapper {
    //background-color: white;
    margin-left: 5%;
    margin-right: 5%;
  }

  .standard-modal {
    --width: 600px;
    --height: 550px;
  }

  .foot-wrapper {
    position: fixed;
    max-width: var(--curafida-wrapper-width);
    //padding: 10px 50px;
    left: 0;
    z-index: 5;
    bottom: 0
  }

  .form-input {
    padding-left: 0;
    width: 50%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .form-button {
    padding-left: 0;
    width: 50%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .page-title {
    font-size: 1.2rem;
    padding-bottom: 1vh;
    padding-top: 2vh;
    border-bottom: 1px solid var(--ion-color-primary);
  }

  .web {
    display: none !important;
  }


  .dashboard-btn {
    padding: 0.5rem;
    max-width: 30vw;
    max-height: 30vw;
    min-width: 8vw;
    min-height: 10vw;
    box-shadow: #5b6a79;
    flex-grow: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dashboard-icon {
    font-size: 15vw;
  }

  .wrapper-without-height-login{
    margin-left: auto;
    margin-right: auto;
    padding: 0 !important;
    --padding: 0 !important;
    max-width: var(--curafida-wrapper-width);
  }
}

#block-overlay {
    /* font*/
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 0; // same size h2 = 1.5em https://html.spec.whatwg.org/multipage/rendering.html#sections-and-headings
    /* position */
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    z-index: 1000;
    /* colors */
    background-color: rgba(17, 121, 200, 0.85);
    opacity: 0;
    /* alignment */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* animation */
    transition: all 0.5s ease-out;
    animation-duration: 500ms;
    animation-fill-mode: both;
}

@keyframes show {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
        height: 0;
        width: 0;
    }

    to {
        opacity: 1;
        height: 100vh;
        width: 100vw;
        transform: none;
        font-size: 1.5em;
    }
}

@keyframes hide {
    from {
        opacity: 1;
        height: 100vh;
        width: 100vw;
    }

    to {
        opacity: 0;
        height: 0;
        width: 0;
        transform: translate3d(0, -100%, 0);
    }
}

#block-overlay.hide {
    animation-name: hide;
}

#block-overlay.show {
    animation-name: show;
}

