/**
Global card
*/
ion-card {
    color: black !important;
}

ion-card-content {
    padding-left: calc(var(--ion-padding, 16px)) !important;
    padding-right: calc(var(--ion-padding, 16px)) !important;
}

/*
TODO: Move to a local component or in a global file
 */
.no-left-right-margin {
    margin-left: 0;
    margin-right: 0;
}

/*
TODO: Move to a local component or in a global file
 */
.no-margin-above {
    margin-top: 0;
}
/*
TODO: Move to a local component or in a global file
 */
.large-margin-bottom {
    margin-bottom: 36px;
}

/*
 Should it be used in curafida-card or in ion-card?
 */
.header-disabled {
    color: #ffffff;
    border-bottom: 1px solid var(--ion-color-medium-tint);
    background: var(--ion-color-medium-tint);
}

/*
 Should it be used in curafida-card or in ion-card?
 */
.border-disabled {
    color: #000;
    border-bottom: 1px solid var(--ion-color-medium-shade);
}

/*
 Should it be used in curafida-card or in ion-card?
 */
.border-enabled {
    color: #000;
    border-bottom: 1px solid var(--ion-color-light-shade);
}

ion-card-header {
    color: var(--ion-color-primary-contrast);
    border-bottom: 1px solid var(--ion-color-light-shade);
    background: var(--ion-color-primary)
}

ion-card-content {
    --padding-top: var(--ion-padding, 1vh ) !important;
    --padding-bottom: var(--ion-padding, 1vh) !important;
    padding-top: var(--ion-padding, 1vh) !important;
    padding-bottom: var(--ion-padding, 1vh) !important;
}


// Mobile device
@media (max-width: 768px), (max-height: 570px) {
    ion-card {
        margin-left: 5px !important;
        margin-right: 5px !important;
        width: auto !important;
        min-width: 0;
    }
}

/*
 Should it be defined globally?
 */
.no-vertical-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/*
 Should it be defined globally?
 */
.no-vertical-margin {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

/*
 Should it be defined globally? or in local component?
 */
.no-horizontal-margin {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.card-radius {
    border-radius: 10px;
    box-shadow: 2px 1px 1px 1px #828282;
    background: #fff;
    padding: 5px;
    margin-left: 5px !important;
    margin-right: 5px !important;
}
